<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import FaqMixin from '@/mixins/faq'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import ServicesCard from '../../components/basket/ServicesCard.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'
import orderService from '../../services/order'
import SuccessAlert from '@/components/basket/SuccessAlert.vue'
import StandardModal from '@/components/StandardModal.vue'
import ReinsuranceMessages from '../../components/basket/ReinsuranceMessages.vue'
import UserAddress from '@/components/basket/Address.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption, SuccessAlert, StandardModal, ReinsuranceMessages, UserAddress}, 
    mixins: [orderMixin, merchantMixin, FaqMixin],
    data() {

        return {

            isDownloading: false,
            isLoading: false,
            error: '',
            helperCondition: false
        }
    },
    created() {
      
        if (this.$route.query.l == 1) {

            this.isLoading = true
        }

        this.$store.commit('setCurrentStep', 50)
    },
    methods: {

        async downloadDeliveryNote() {

            this.error = ''
            this.isDownloading = true
            let newWindow = null

            let response = await this.$refs.addressForm.createAddress()
            if (response !== true) {

                this.error = response
                this.isDownloading = false

                return
            }

            try {

                if (!this.isMobile) {

                    newWindow = window.open(window.location.href + '?l=1');
                }

                let response = await orderService.getDeliveryNote(this.order.public_id, this.isMobile)
                this.$tracking.logEvent('Delivery Note Generated', {'Order ID': this.order.public_id})
                
                if (!this.isMobile) {

                    newWindow.location = response.data.url
                }

                this.$router.push({name: 'orderConfirm', params: {id: this.order.public_id}, query: {f: 1}})
            }
            catch (e) {

                newWindow.close()
                this.error = 'Une erreur est survenue lors de la création de l\'étiquette. Veuillez contacter le support.'
                this.isDownloading = false
                this.$tracking.logEvent('Delivery Note Error', {'Order ID': this.order.public_id})
            }
        },
    },
    computed: {
        isTransferSigned() {
            return this.isOrderLoaded && this.order.is_transfer_signed
        },
        isMobile() {
            
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === true
        },
        deliveryDelay() {

            let baseDays = 7
            if (this.order.coupon_type === 'IMMEDIAT') {

                if (this.isPreco) {

                    return 15 + baseDays
                }

                return baseDays
            }

            if (this.completedAt === null) {

                return baseDays; 
            }

            let todaysDate = new Date()
            let timeDifference = todaysDate.getTime() -  this.completedAt.getTime()
            let daysDifference = timeDifference / (1000 * 3600 * 24)
            let left = baseDays - parseInt(daysDifference)

            if (left < 0) return 0

            if (left > baseDays) return baseDays

            return left
        },
        completedAt() {

            if (this.order.completed_at == undefined) return null

            return new Date(this.order.completed_at)
        },
        resellerPosition() {

            if (this.order.reseller.can_accept_delivery_on_site == false) return null

            return {lat:this.order.reseller.position_coordinates.latitude, lng:this.order.reseller.position_coordinates.longitude}
        },
        isPreco() {

            return this.order && this.order.brand_campaign && this.order.brand_campaign.can_renew_preauth == true
        },
    },
    watch: {
        isOrderLoaded() {

            this.$tracking.pageView('delivery', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="isTransferSigned">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <success-alert v-if="$route.query.t == 1" :message="'Votre bon de cession a bien été signé.'" />
                        <h3>Expédition du produit</h3>
                        <p>Il ne vous reste plus qu’à <strong>expédier gratuitement votre produit</strong>.</p>
                        <p>Vous avez <strong class="text-success">{{deliveryDelay}} jour(s)</strong> pour expédier votre produit.</p>
                        <p>Pour cela, merci de bien utiliser <strong>l’étiquette d’expédition</strong> que vous pouvez <strong>télécharger</strong> grâce au bouton ci-dessous, à coller sur le carton emballant votre produit.</p>
                        <div v-if="error" class="alert alert-danger" role="alert" v-html="error"></div>
                        <user-address :isLoading="isDownloading" ref="addressForm" />
                        <button class="btn btn-success mb-3 mt-2" :disabled="isDownloading" type="button" v-on:click="downloadDeliveryNote()">
                            <span v-if="!isMobile">Je télécharge l'étiquette d'expédition</span>
                            <span v-if="isMobile">Je reçois par email l'étiquette d'expédition</span>
                            <span v-if="isDownloading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn-helper" @click="helperCondition = true">Pourquoi renseigner mon adresse postale ?</button>
                        <section v-if="order.reseller.can_accept_delivery_on_site">
                            <p class="mt-3">OU</p><br>
                            <p>Vous pouvez aussi <strong>déposer votre produit en magasin</strong> à l'adresse suivante :<br> <strong>{{order.reseller.legal_address}}, {{order.reseller.legal_address_zipcode}} {{order.reseller.legal_address_city}}</strong></p>
                            <gmap-Map
                                :center="resellerPosition"
                                :zoom="12"
                                style="width:100%; height: 300px;"
                                >
                                
                                <gmap-marker
                                
                                :position="resellerPosition"
                                >
                                </gmap-marker>
                            </gmap-Map>
                        </section>
                        <div class="card my-4">
                            <div class="card-body row pb-2">
                                <div class="col-1 d-flex align-items-center justify-content-center">
                                    <i class="bi bi-question-circle text-success font-size-2rem"></i>
                                </div>
                                <div class="col">
                                    <p><strong>Comment emballer votre produit ?</strong>
                                    <br/>Retrouvez nos conseils pour bien emballer votre colis en <a :href="getFAQDeliveryURL()" target="_blank">cliquant ici</a>.</p>
                                </div>
                            </div>
                        </div>
                        <reinsurance-messages class="d-none d-md-block" v-if="isMerchantLoaded && Object.keys($t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.DELIVERY')).length > 0" :messages="$t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.DELIVERY')" />
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="orderPrice" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption v-if="merchant" :hasImmediat="merchant.can_have_immediat_coupon" />
                </div>
            </div>
        </div>
        <standard-modal v-if="isLoading" :title="'Veuillez patienter pendant le chargement de votre étiquette de transport'" :closeLabel="'Fermer'" @close="isLoading = false">
            <template v-slot:body>
                <div class="d-flex flex-column justify-content-center">
                    <div class="spinner-border m-auto" style="width: 5rem; height: 5rem;" role="status"></div>
                </div>
            </template>
        </standard-modal>
        <standard-modal v-if="helperCondition" :title="'Pourquoi renseigner mon adresse postale ?'" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="helperCondition = false">
            <template v-slot:body>
                <p>Nous avons besoin de votre adresse postale afin de la mentionner sur l'étiquette de transport en tant qu'expéditeur.<br/>En cas de soucis durant le transport, votre produit reviendra chez vous.</p>
            </template>
        </standard-modal>
    </basket-layout>
</template>
<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button.btn-helper {

    text-decoration: underline;
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 0.8em;
}
</style>
