<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import orderService from '../../services/order'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import ServicesCard from '../../components/basket/ServicesCard.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'
import StandardModal from '../../components/StandardModalV2.vue'
import SuccessAlert from '@/components/basket/SuccessAlert.vue'
import moment from 'moment'
import CancelOrderButton from '@/components/basket/CancelOrderButton.vue'
import ReinsuranceMessages from '../../components/basket/ReinsuranceMessages.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption, StandardModal, SuccessAlert, CancelOrderButton, ReinsuranceMessages},
    mixins: [orderMixin, merchantMixin],
    data() {
        return {

            choosenType: null,
            modalImmediatPayment: false,
            error: '',
            isLoading: false,
            withCanBeImmediat: true,
            modalCancelOrder: false,
            displayInformation: false,
            randomNumber: Math.floor(Math.random() * 100)
        }
    },
    created() {
      
        this.$tracking.pageView('choose coupons type', 'order', {'Order ID': this.orderId})
        this.$store.commit('setCurrentStep', 30)
    },
    methods: {

        toggleInformation() {

            this.displayInformation = this.displayInformation ? false : true
        },
        clickChoose() {

            if (this.choosenType == undefined) {

                this.error = "Veuillez choisir un type de " + this.order.merchant.coupon_name
            }
            else {

                this.choosenType == 'IMMEDIAT' ? this.modalImmediatPayment = true : this.chooseType()
            }
        },
        async chooseType() {

            this.isLoading = true
            await orderService.chooseType(this.orderId, this.choosenType)
            if (this.choosenType == 'IMMEDIAT') {
                
                if (this.isPreAuthMango) {

                    try {

                        this.$tracking.logEvent('Coupon type chosen', {'Order ID': this.orderId, 'Type': this.choosenType, 'PSP': 'mangopay'})  

                        this.$router.push({name: 'mangoCardForm', query: {dc:1}})
                        
                        this.isLoading = false
                    } 
                    catch(e) {

                        this.isLoading = false
                    }
                }
                else {

                    let response = await orderService.createImmediatePayment(this.orderId)
                    response = response.data
                    
                    this.$tracking.logEvent('Coupon type chosen', {'Order ID': this.orderId, 'Type': this.choosenType, 'PSP': 'swikly'})    

                    window.location.replace(response.acceptUrl)
                }
            }
            else {

                this.$tracking.logEvent('Coupon type chosen', {'Order ID': this.orderId, 'Type': this.choosenType})

                this.$router.push({name: 'orderKyc', query : {k:1}})
            }
        },
        isUserRecentlyCreated() {

            let user = this.$store.getters.user
            let minusHour = moment().subtract(1, 'hours')

            return moment(user.created_at).isAfter(minusHour)
        },
        closeModalImmediatPayment() {

            this.modalImmediatPayment = false

            this.$tracking.logEvent('Immediat coupon modal closed', {'Order ID': this.orderId})
        },
        closeModalCancelOrder() {
            
            this.modalCancelOrder = false

            this.$tracking.logEvent('Cancel order closed', {'Order ID': this.orderId})
        },
        clickImmediat() {

            if (this.order.can_be_immediat === true)
                this.choosenType = 'IMMEDIAT'
        },
        clickPostponed() {

            if (this.order.can_be_postponed === true)
                this.choosenType = 'POSTPONED'
        },
        getTypeLabel(couponName) {

            let label = ''
            if (this.orderPrice > 0) {

                label = this.$options.filters.capitalize(couponName)
            }

            if (this.order.bonus_amount > 0) {

                if (label != '') {

                    label = label + ' et'
                }

                if (label == '') {

                    label = 'Code de réduction'
                }
                else {

                    label = label + ' code de réduction'
                }
            }

            return label
        },
        async cancelOrder() {

            try {

                this.$tracking.logEvent('Cancel Order Clicked', {'Order ID': this.orderId, 'Page Name': 'choose coupons type'})
                
                let redirectionUrl = await orderService.cancelOrderById(this.orderId);
                window.location.href = redirectionUrl.data;
            }
            catch (error) {

                console.log(error);
            }
        },
    }, 
    computed: {
        isPreAuthMango() {

            return this.randomNumber <= process.env.VUE_APP_PREAUTH_MANGOPAY_PERCENT
        },
        isPreco() {

            return this.order && this.order.brand_campaign && this.order.brand_campaign.can_renew_preauth == true
        },
        reclaimPreauthDelayDays() {

            let baseDays = 7

            if (this.isPreco) return 15 + baseDays;

            return baseDays;
        }
    },
    watch: {
        choosenType(type) {

            if (type == '') {

                this.error = "Veuillez choisir un type de " + this.order.merchant.coupon_name
            }
            else {

                this.error = ''

                if (type === 'IMMEDIAT' && this.order.can_be_immediat !== true) {

                    this.error = 'Votre reprise n\'est actuellement pas éligible en ' + this.merchant.coupon_name + ' - immédiat'
                }

                this.$tracking.logEvent('Click coupon type', {'Order ID': this.orderId, 'Type': this.choosenType})
            }
        }
    }
}
</script>
<style scoped>
    .btn-outline-danger {
        background-color: white !important;
    }
    .btn-outline-danger:hover {
        background-color: #f34770 !important;
    }
   .card-active:hover {
        border-color: #21d5b6 !important;
    }
    .card-inactive {
        color: grey !important;
        border-color: grey !important;
    }
</style>
<template>
    <basket-layout>
        <div v-if="this.order">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <success-alert class="col-md-11" :message="'Félicitations, votre compte de revente <strong class=&quot;text-success&quot;>' + merchantName + '</strong> est finalisé.'" v-if="isUserRecentlyCreated" />
                    <div class="ps-3">
                        <h3>Votre {{ order.merchant.coupon_name }}</h3>
                        <span>Recevez votre {{order.merchant.coupon_name}} immédiatement ou attendez la réception par nos services de votre produit</span>
                    </div>
                    <div class="mx-0 row">
                        <div class="mt-4 px-0 col-md-5">
                            <div class="card shadow-lg" id="selectCard" :class="{'border-success-lg': choosenType == 'IMMEDIAT', '': choosenType == 'POSTPONED' || choosenType == null, 'opacity-25': order.can_be_immediat !== true}" role="button" @click="clickImmediat">
                                <div class="card-body py-2">
                                    <div class="row d-flex align-items-center">
                                        <div class="offset-1 col-9 pt-2 ps-0">
                                            <p><strong class="text-success">IMMÉDIATEMENT</strong></p>
                                        </div>
                                        <div class="col px-0">
                                            <img src="/assets/img/icon-bon-immediat.svg" width="40" height="40"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2 d-flex justify-content-center align-items-center px-0 pb-5">
                                            <input id="typeImmediat" :disabled="order.can_be_immediat !== true" class="form-check-input border-success-lg" type="radio" :checked="choosenType == 'IMMEDIAT'">
                                        </div>
                                        <div class="col-10 d-flex flex-column px-0">
                                            <p><strong>{{getTypeLabel(order.merchant.coupon_name)}}<br/><span class="text-success">disponible dans 5 minutes</span></strong><br/><small>Valable {{merchant.coupon_validity_period}}</small></p><br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2 d-flex align-items-center" :class="{'opacity-25': order.can_be_immediat !== true}">
                                <i class="col-2 bi bi-credit-card d-flex justify-content-end px-0 text-success font-size-2rem"/>
                                <p class="small col-10 mb-0"><strong>Empreinte de votre carte bleue nécessaire</strong><br/>Aucun montant ne sera prélevé</p>
                            </div>
                            <div class="row mt-2 d-flex align-items-center" v-if="order.can_be_immediat !== true">
                                <p class="small text-danger">Votre reprise n'est pas éligible à la reprise immédiate.</p>
                            </div>
                        </div>
                        <div class="mt-4 px-0 offset-md-1 col-md-5">
                            <div class="card shadow-lg" id="selectCard" :class="{'border-success-lg': choosenType == 'POSTPONED', '': choosenType == 'IMMEDIAT' || choosenType == null, 'opacity-25': order.can_be_postponed !== true}" role="button" @click="clickPostponed">
                                <div class="card-body py-2">
                                    <div class="row d-flex align-items-center">
                                        <div class="offset-1 col-9 pt-2 ps-0">
                                            <p><strong class="text-success">A RÉCEPTION</strong></p>
                                        </div>
                                        <div class="col px-0">
                                            <img src="/assets/img/icon-bon-differe.svg" width="40" height="40"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2 d-flex justify-content-center align-items-center px-0 pb-5">
                                            <input id="typePostponed" :disabled="order.can_be_postponed !== true" class="form-check-input border-success-lg" type="radio" :checked="choosenType == 'POSTPONED'">
                                        </div>
                                        <div class="col-10 d-flex flex-column px-0">
                                            <p><strong>{{getTypeLabel(order.merchant.coupon_name)}}<br/><span class="text-success">disponible après réception par nos services de votre produit</span></strong><br/><small>Valable {{merchant.coupon_validity_period}}</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2 d-flex align-items-center">
                                <i class="col-2 bi bi-credit-card d-flex justify-content-end px-0 font-size-2rem opacity-25"/>
                                <p class="small col-10 mb-0"><strong>Pas d'empreinte de votre carte bleue</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mb-4 col-md-11">
                        <div class="col text-center">
                            <div v-if="error != ''" class="offset-md-4 col-md-6 alert alert-danger">{{error}}</div>
                            <button :disabled="choosenType == undefined || isLoading" class="btn btn-success" @click="clickChoose">
                                Je valide mon choix
                                 <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                    <reinsurance-messages class="d-none d-md-block" v-if="isMerchantLoaded && Object.keys($t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.COUPON_TYPE')).length > 0" :messages="$t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.COUPON_TYPE')" />
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="orderPrice" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                        <cancel-order-button v-if="order.active_counter_offer === null && order.accepted_counter_offer === null" v-model="modalCancelOrder" @click="modalCancelOrder = true"/>
                        <!-- <cancel-order-button v-model="modalCancelOrder" @click="modalCancelOrder = true"/> -->
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption v-if="merchant" :hasImmediat="merchant.can_have_immediat_coupon" />
                </div>
            </div>
        </div>

        <standard-modal v-if="modalImmediatPayment == true" :closeLabel="'Fermer'" @close="closeModalImmediatPayment()">
            <template v-slot:body>
                <div class="row d-flex align-items-center mb-4">
                    <h4 class="col-7 pe-0 mb-0">{{ order.merchant.coupon_name.toUpperCase() }} <span class="text-success">"IMMEDIATEMENT"</span></h4>
                    <img class="col-1 pe-4 ps-0" src="/assets/img/icon-bon-immediat.svg"/>
                    <span class="offset-3 col-1"><button class="btn" @click="closeModalImmediatPayment">X</button></span>
                </div>
                <div>
                    <p>Vous avez choisi de recevoir votre {{ getTypeLabel(order.merchant.coupon_name) }} <strong>immédiatement</strong>, vous pourrez l'utiliser pour un nouvel achat dans moins de 5 minutes !</p>
                    <p v-if="isPreAuthMango">Vous allez être redirigé vers notre formulaire sécurisé afin de réaliser une empreinte de votre CB.</p>
                    <p v-else>Nous allons vous rediriger vers le formulaire sécurisé de notre partenaire <strong>SWIKLY</strong> afin de réaliser une empreinte de votre CB.</p>
                    <p><strong class="text-success">Vous ne serez pas débité !</strong><br/>Il s'agit uniquement d'une <strong>pré-autorisation</strong> (visible sur votre compte chez certaines banques)</p>
                </div>
                <div class="row d-flex justify-content-center mt-4">
                    <button :disabled="isLoading" role="button" class="btn btn-success d-flex justify-content-center col-5 " @click="chooseType()">Validez le choix <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></button>
                    <button role="button" :disabled="isLoading" class="btn btn-outline-danger d-flex justify-content-center offset-1 col-5" @click="closeModalImmediatPayment()">Retour</button>
                </div>
                <div class="row text-center mt-1 mb-3">
                    <span class="col-6 small">Vous recevrez votre {{getTypeLabel(order.merchant.coupon_name)}} dans 5 minutes</span>
                </div>
                <div class="card mt-4">
                    <div class="card-body py-3">
                        <span>Causes possibles de prélèvement<button class="btn p-2" @click="toggleInformation"><strong>{{displayInformation ? '-' : '+'}}</strong></button></span>
                        <ol :class="displayInformation ? '' : 'd-none'">
                            <li class="my-3">
                                <strong>Produit non conforme au déclaratif<span class="mx-3">ET</span></strong>Vous <strong>acceptez</strong> la contre-offre
                                <br/><i>Débit de la différence entre l'offre initiale et la contre-offre</i>
                            </li>
                            <li class="my-3">
                                <strong>Produit non conforme au déclaratif<span class="mx-3">ET</span></strong>Vous <strong>refusez</strong> la contre-offre
                                <br/><i>Débit de la totalité de votre {{getTypeLabel(order.merchant.coupon_name)}} + le montant du bonus reprise si éligible</i>
                            </li>
                            <li class="my-3">
                                <strong>Produit non expédié <span class="text-success">dans les {{ reclaimPreauthDelayDays }} jours</span></strong>
                                <br/><i>Débit de la totalité de votre {{getTypeLabel(order.merchant.coupon_name)}} + le montant du bonus reprise si éligible</i>
                            </li>
                            <li class="my-3">
                                <strong>Pièce d'identité non fournie ou non validée <span class="text-success">dans les {{ reclaimPreauthDelayDays }} jours</span></strong>
                                <br/><i>Débit de la totalité de votre {{getTypeLabel(order.merchant.coupon_name)}} + le montant du bonus reprise si éligible</i>
                            </li>
                        </ol> 
                    </div>
                </div>
            </template>
        </standard-modal>
        
        <standard-modal v-if="modalCancelOrder">
            <template v-slot:body>
                <p> Êtes vous sûr de vouloir annuler votre reprise ?</p>
                <div class="d-flex justify-content-around">
                    <button role="button" class="btn btn-outline-danger" @click="cancelOrder()">Confirmer l'annulation</button>
                    <button role="button" class="btn btn-gd-dark"  @click="closeModalCancelOrder()"><span class="text-white">Retour</span></button>
                </div>
            </template>
        </standard-modal>

    </basket-layout>
</template>